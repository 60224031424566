import React, { useEffect, useState } from "react"

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledTooltip
} from "reactstrap"

import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { changeUserPassword, createDomainProject, getDomainUrls, getUserDetails, updateUserSettings } from "services/main.service"
import Loading from "components/Common/Loading"

import toastr from "toastr";

const EmailSettings = () => {

    //meta title
    document.title = "Settings | Indexed Checker App";
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState()
    const [userSettingsDets, setUserSettingsDets] = useState()
    const [emailTimePeriod, setEmailTimePeriod] = useState()
    const [emailAction, setEmailAction] = useState()
    const [emailSendTime, setEmailSendTime] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const [togCreditLowEmail, setTogCreditLowEmail] = useState(true);

    const [passwordShow, setPasswordShow] = useState(false);

    const [currnetPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [settingsData, setSettingsData] = useState();
    const [couponData, setCouponData] = useState();
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('authUser')));
    const [inviteURl, setInviteUrl] = useState('https://app.indexedchecker.com/register?partner=' + userData?.username);

    useEffect(() => {
        getUserDetails().then(res => {
            console.log(res.data, 'user_dets');
            setUserDetails(res.data.user_data)
            setUserSettingsDets(res.data.user_settings)
            setCouponData(res.data.user_credits.coupon)
            setEmailTimePeriod(res.data.user_settings.email_time_period)
            setEmailAction(res.data.user_settings.email_action)
            setEmailSendTime(res.data.user_settings.email_send_time)
            setTogCreditLowEmail(res.data.user_settings.credit_low_email ? true : false)
            setSettingsData({
                email_time_period: res.data.user_settings.email_time_period,
                email_action: res.data.user_settings.email_action,
                email_send_time: res.data.user_settings.email_send_time,
                credit_low_email: res.data.user_settings.credit_low_email ? true : false,
                coupon: res.data.user_credits.coupon,
            })
            setIsLoading(false)
        }).catch((error) => {
            console.log({ error });
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        })
    }, [])

    const handleEmailSettings = () => {
        const settings_data = {
            email_time_period: emailTimePeriod,
            email_action: emailAction,
            email_send_time: emailSendTime,
            credit_low_email: togCreditLowEmail ? 1 : 0,
            coupon: settingsData.coupon
        }
        updateUserSettings(settings_data).then((res) => {
            console.log(res, "response");

            toastr.success("Settings Updated!")
        }).catch((error) => {
            console.log({ error });
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const updateProfileDets = () => {
        if (userSettingsDets?.first_name == null && userSettingsDets?.last_name == null && userSettingsDets?.company_name == null) {
            toastr.error("Please Enter Details!")
        } else {
            updateUserSettings(userSettingsDets).then((res) => {
                console.log(res, "response");

                toastr.success("Profile Updated!")
            }).catch((error) => {
                console.log({ error });
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const handleChangePassword = () => {
        if (newPassword !== confirmPassword) {
            toastr.error("New Passwords Mismatch!")
        } else {
            const password_data = {
                current_password: currnetPassword,
                new_password: newPassword
            }

            changeUserPassword(password_data).then((res) => {
                console.log(res, "response");

                toastr.success("Password Changed Successfully!")
            }).catch((error) => {
                console.log({ error });
                toastr.error(error.response.data.error)
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(inviteURl).then(() => {
            toastr.success("URL Copied!")
        })
            .catch((error) => {
                console.error('Failed to copy invite link: ', error);
            });
    }


    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }
                <Container fluid={true}>
                    {/* <Breadcrumbs title="Domain Monitor" breadcrumbItem="New Domain Monitor Project" /> */}

                    <Row>

                        <Col lg="12">
                            <Card className="border border-primary">
                                <CardBody>
                                    <CardTitle className="mb-4">Email Notifications</CardTitle>
                                    {/* <h4 className="card-title">Settings</h4> */}
                                    <Row>
                                        <h5 className="font-size-15 mt-3">Report Email {" "}
                                            <i className="far fa-question-circle" id="recheckperiodtool" />
                                            <UncontrolledTooltip placement="top" target="recheckperiodtool">
                                                You can mange sytem mails from here
                                            </UncontrolledTooltip></h5>
                                        <Col lg={6}>
                                            <div className="mt-2">

                                                <div>
                                                    <div
                                                        className="btn-group btn-group-example mb-3"
                                                        role="group"
                                                    >
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary w-lg ${emailTimePeriod == "None" ? 'active' : null}`}
                                                            onClick={() => setEmailTimePeriod("None")}
                                                        >
                                                            None
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary w-lg ${emailTimePeriod == "Daily" ? 'active' : null}`}
                                                            onClick={() => setEmailTimePeriod("Daily")}
                                                        >
                                                            Daily
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary w-lg ${emailTimePeriod == "Weekly" ? 'active' : null}`}
                                                            onClick={() => setEmailTimePeriod("Weekly")}
                                                        >
                                                            Weekly
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary w-lg ${emailTimePeriod == "Monthly" ? 'active' : null}`}
                                                            onClick={() => setEmailTimePeriod("Monthly")}
                                                        >
                                                            Monthly
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mt-2">
                                                <input
                                                    className="form-control"
                                                    type="time"
                                                    defaultValue="13:45:00"
                                                    id="example-time-input"
                                                    value={emailSendTime}
                                                    onChange={(e) => { setEmailSendTime(e.target.value) }}
                                                />
                                            </div>

                                        </Col>
                                        {/* <Col lg={2}>
                                            <div className="mt-2">
                                                <select className="form-control" name="schedule" id="schedule"
                                                    value={emailAction} onChange={(e) => { setEmailAction(e.target.value) }}>
                                                    <option value="0">All</option>
                                                    <option value="1">Any Change</option>
                                                    <option value="2">Indexed Pages</option>
                                                    <option value="3">De-indexed Pages</option>
                                                </select>
                                            </div>

                                        </Col> */}
                                        <Col lg={2}>
                                            <div className="mt-2">
                                                <Button className="btn btn-success" onClick={handleEmailSettings}>
                                                    Save
                                                </Button>
                                            </div>

                                        </Col>

                                    </Row>
                                    <Row>
                                        {/* <Col lg={4}>
                                            <div className="form-check-primary col-sm-auto">
                                                <Label
                                                    htmlFor="coupon_Code"
                                                    className="col-form-label col-lg-12"
                                                >
                                                    Coupon Code
                                                </Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="coupon_Code"
                                                    value={settingsData?.coupon}
                                                    onChange={(e) => { setSettingsData({ ...settingsData, coupon: e.target.value }) }}
                                                    readOnly={couponData != null}
                                                />

                                            </div>

                                        </Col> */}
                                        <Col lg={4}>
                                            <div className="form-check form-check-primary mb-3 col-sm-auto">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="credit_low_email"
                                                    onClick={() => setTogCreditLowEmail(!togCreditLowEmail)}
                                                    checked={togCreditLowEmail}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="credit_low_email"
                                                >
                                                    Send Credit Low Email
                                                </label>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <h5 className="font-size-15 mt-3">Invite Link</h5>
                                        <Col lg={6}>
                                            <div className="form-check-primary col-sm-auto">
                                                {/* <Label
                                                    htmlFor="coupon_Code"
                                                    className="col-form-label col-lg-12"
                                                >
                                                    Invite Link
                                                </Label> */}
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="inviteURl"
                                                    value={inviteURl}
                                                    readOnly
                                                />

                                            </div>

                                        </Col>
                                        <Col lg={2}>
                                            <div className="mt-0">
                                                <Button className="btn btn-success" onClick={handleCopyLink}>
                                                    Copy Link
                                                </Button>
                                            </div>

                                        </Col>
                                    </Row>


                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Profile Settings</CardTitle>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            updateProfileDets(e);
                                        }}
                                    >
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup row>
                                                    <Label
                                                        htmlFor="project_name"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        Email
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            readOnly
                                                            disabled
                                                            className="form-control"
                                                            value={userDetails?.email}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup row>
                                                    <Label
                                                        htmlFor="project_name"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        Username
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="username"
                                                            name="username"
                                                            type="text"
                                                            readOnly
                                                            disabled
                                                            className="form-control"
                                                            value={userDetails?.username}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup row>
                                                    <Label
                                                        htmlFor="first_name"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        First Name
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="first_name"
                                                            name="first_name"
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => { setUserSettingsDets({ ...userSettingsDets, first_name: e?.target?.value }) }}
                                                            value={userSettingsDets?.first_name || ''}
                                                            placeholder="Enter first name..."
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup row>
                                                    <Label
                                                        htmlFor="last_name"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        Last Name
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="last_name"
                                                            name="last_name"
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => { setUserSettingsDets({ ...userSettingsDets, last_name: e?.target?.value }) }}
                                                            value={userSettingsDets?.last_name || ''}
                                                            placeholder="Enter last name..."
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup className="mb-2" row>
                                                    <Label
                                                        htmlFor="company_name"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        Company Name
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="company_name"
                                                            name="company_name"
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => { setUserSettingsDets({ ...userSettingsDets, company_name: e?.target?.value }) }}
                                                            value={userSettingsDets?.company_name || ''}
                                                            placeholder="Enter company name..."
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-end mt-2">
                                            <Col lg="12">
                                                <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                    Update Profile
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Form>

                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Change Password</CardTitle>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleChangePassword(e);
                                        }}
                                    >

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup row>
                                                    <Label
                                                        htmlFor="first_name"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        Current Password
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="cur_pasword"
                                                            name="cur_pasword"
                                                            type="password"
                                                            className="form-control"
                                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                                            required
                                                        />
                                                        {/* <div className="input-group auth-pass-inputgroup">
                                                            <Input
                                                                name="password"
                                                                type={passwordShow ? "text" : "password"}
                                                                placeholder="Enter Password"
                                                            />
                                                            <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                                                <i className="bx bx-table"></i></button>
                                                        </div> */}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup className="mb-2" row>
                                                    <Label
                                                        htmlFor="new_password"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        New Password
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="new_password"
                                                            name="new_password"
                                                            type="password"
                                                            className="form-control"
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            required
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup className="mb-2" row>
                                                    <Label
                                                        htmlFor="confirm_password"
                                                        className="col-form-label col-lg-12"
                                                    >
                                                        Confirm Password
                                                    </Label>
                                                    <Col lg="12">
                                                        <Input
                                                            id="confirm_password"
                                                            name="confirm_password"
                                                            type="password"
                                                            className="form-control"
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            required
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-end mt-2">
                                            <Col lg="12">
                                                <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                    Change Password
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EmailSettings
